<template>
    <div v-if="suggestProductList">
        <div class="h5 font-weight--sm--500 mb--sm--3">Gợi ý cho bạn:</div>
        <div class="row" v-if="suggestProductList">
          <div class="col col--sm--12 col--md--4 col--lg--3 mb--sm--6" v-for="suggestProductItem in suggestProductList" :key="suggestProductItem.productCode">
            <SuggestItem :productItem="suggestProductItem"/>
          </div>
        </div>
        <div class="text--sm--right mb--sm--10 mb--md--20">
          <HdButton v-on:click="$_.isEmpty(groupCode) ? $router.push('/').catch(()=>{}) :  $router.push(`/category/${groupCode}`).catch(()=>{})">
            <template v-slot:icon><PlusIcon /></template>
            Thêm sản phẩm
          </HdButton>
        </div>
    </div>
</template>

<script>
import SuggestItem from '@/components/SuggestItem'
import HdButton from '@/components/HdButton'
import PlusIcon from '@/assets/plus-icon.svg'

export default {
  components: {
    SuggestItem,
    HdButton,
    PlusIcon
  },
  computed: {
    groupCode () {
      return this.$store.state.product.groupCode
    },
    suggestProductList () {
      return this.$store.state.cart.suggestProductList
    }
  },
  mounted () {
    this.$store.dispatch('cart/getSuggestProduct')
  }
}
</script>
