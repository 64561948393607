var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ctkm"},[_c('div',{staticClass:"ctkm__choose"},[_c('div',{staticClass:"ctkm__choose__item"},[_c('div',{staticClass:"ctkm__label"},[_vm._v("CTKM HĐ")]),_c('div',{staticClass:"ctkm__icon",class:[_vm.promotionType ==='HD' ? 'ctkm__icon--is-active' : ''],on:{"click":function(){
        _vm.setPromotionType('HD')
        _vm.setSaleDeal({})
      }}})]),_c('div',{staticClass:"ctkm__choose__item"},[_c('div',{staticClass:"ctkm__label"},[_vm._v("CTKM LẺ")]),_c('div',{staticClass:"ctkm__icon",class:[_vm.promotionType ==='LE' ? 'ctkm__icon--is-active' : ''],on:{"click":function(){
        _vm.setPromotionType('LE')
        _vm.setSaleDeal({})
      }}})])]),_c('div',{staticClass:"ctkm__list"},[_c('div',{staticClass:"ctkm__item"},[_c('div',{staticClass:"ctkm__item__name"},[_vm._v(_vm._s(_vm.$_.isEmpty(_vm.saleDeal) ? 'CTKM' : _vm.saleDeal.name))]),_c('div',{staticClass:"ctkm__item__close",on:{"click":function () {
       if (_vm.$_.isEmpty(_vm.saleDeal)) {
        _vm.$store.dispatch('cart/getPromotionCart')
        _vm.dialogVisible = true
       } else {
        _vm.setSaleDeal({})
       }
       }}},[_c('CtkmIcon',{class:[!_vm.$_.isEmpty(_vm.saleDeal) ? 'pluss' : '']})],1)])]),_c('el-dialog',{attrs:{"title":"Chọn chương trình khuyến mãi","visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"ctkm__dialog"},[_vm._l((_vm.ctkm),function(ctkmItem,index){return _c('div',{key:index,staticClass:"ctkm__dialog__item",class:[_vm.saleDeal.value === ctkmItem.promotionCode? 'ctkm__dialog__item--is-selected' : ''],on:{"click":function(){
          _vm.setSaleDeal({
            value: ctkmItem.promotionCode,
            name: ctkmItem.promotionName
          })
          _vm.dialogVisible = false
        }}},[(ctkmItem.promotionCode)?_c('div',{staticClass:"ctkm__dialog__item__code"},[_vm._v(_vm._s(ctkmItem.promotionCode))]):_vm._e(),(ctkmItem.promotionName)?_c('div',{staticClass:"ctkm__dialog__item__name"},[_vm._v(_vm._s(ctkmItem.promotionName))]):_vm._e(),(_vm.saleDeal.value === ctkmItem.promotionCode)?_c('CheckIcon',{staticClass:"ctkm__dialog__item__check-icon mr--sm--4"}):_vm._e()],1)}),(_vm.$_.isEmpty(_vm.ctkm))?_c('div',{staticClass:"text--sm--center"},[_vm._v("Không có chương trình khuyến mãi nào")]):_vm._e()],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }