<template>
  <div class="ctkm">
    <div class="ctkm__choose">
      <div class="ctkm__choose__item">
        <div class="ctkm__label">Boston App Extra</div>
        <div
          class="ctkm__icon"
          :class="active ? 'ctkm__icon--is-active' : ''"
          style="cursor: not-allowed;"
        ></div>
      </div>
    </div>
    <div class="ctkm__list">
      <div class="ctkm__item" v-if="$store.state.customer.promotionX1Code != '' && $store.state.customer.promotionX1Des != ''">
        <div class="ctkm__item__name">{{ $store.state.customer.promotionX1Des }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    active: Boolean
  }
}
</script>
<style lang="scss" scoped>
.pluss {
  transform: rotate(45deg);
}
.ctkm__choose__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ctkm {
  display: flex;
  align-items: center;
  &__label {
    font-size: 20px;
    font-weight: 500;
    margin-right: 15px;
    line-height: 40px;
    min-width: 160px;
  }
  &__icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: solid 4px #5eb5af;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      opacity: 0.75;
    }
    &--is-active {
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: solid 4px #19958c;
      }
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
  }
  &__item {
    height: 36px;
    line-height: 36px;
    background: rgba(243, 111, 32, 0.15);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    margin-left: 20px;
    &__name {
      font-size: 20px;
      font-weight: 600;
      color: #b93d00;
      text-transform: uppercase;
      padding: 0 8px;
      flex: 1;
    }
    &__close {
      border-left: solid 1px #fff;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 8px;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        opacity: 0.75;
      }
    }
  }
}
.ctkm__dialog {
}
.ctkm__dialog__item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #b93d00;
  padding: 16px 0;
  font-size: 16px;
  font-weight: 500;
  color: #b93d00;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background-color: #fae6da;
  }
  &--is-selected {
    background-color: #fae6da;
  }
}
.ctkm__dialog__item__code {
  font-weight: 600;
  width: 20%;
  max-width: 20%;
}
.ctkm__dialog__item__name {
  flex: 1;
}
</style>
