<template>
    <div class="cart-item">
        <div class="cart-item__left">
            <input type="checkbox" :checked="isChecked" @change="updateInput">
        </div>
        <div class="cart-item__center">
            <div v-if="cartItem.productName">{{cartItem.productName}} <span v-if="cartItem.productSize">{{cartItem.productSize}}</span></div>
            <div>SKU: <small v-if="cartItem.productCode">{{cartItem.productCode}}</small><small v-if="cartItem.groupCode"> - {{cartItem.groupCode}}</small></div>
            <a href="#" title="" @click.prevent="removeItemCartHandle">Xóa</a>
        </div>
        <div class="cart-item__right">
            <div class="cart-item__quality__number"><el-input-number :value="cartItem.quantity" size="mini" :min="1" :max="9999" @change="handleQuantityChange"></el-input-number></div>
            <div class="cart-item__price">{{amoutPrice.toLocaleString('vi-VN', {style : 'currency', currency : 'VND'})}}</div>
            <div class="cart-item__unit">{{cartItem.unitDescription}}</div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    value: {},
    cartItem: Object
  },
  data () {
    return {}
  },
  computed: {
    isChecked () {
      if (this.value instanceof Array) {
        return this.value.includes(this.cartItem.productCode)
      }
      return this.value === true
    },
    amoutPrice () {
      return this.cartItem.price * this.cartItem.quantity
    }
  },
  methods: {
    handleQuantityChange (quantity) {
      if (quantity !== undefined) {
        this.cartItem.quantity = quantity
      } else {
        this.cartItem.quantity = 1
      }
      this.$store.dispatch("customer/getCusPromotion");
      this.$store.commit('cart/updateCartItemsLocalStore')
    },
    updateInput (event) {
      const isChecked = event.target.checked
      if (this.value instanceof Array) {
        const currentValue = [...this.value]
        if (isChecked) {
          currentValue.push(this.cartItem.productCode)
        } else {
          currentValue.splice(currentValue.indexOf(this.cartItem.productCode), 1)
        }
        this.$emit('input', currentValue)
      } else {
        this.$emit('input', isChecked)
      }
      this.$store.dispatch("customer/getCusPromotion");
    },
    removeItemCartHandle () {
      this.$confirm('Bạn có muốn xoá sản phẩm?').then(() => {
        this.$store.dispatch('cart/removeItem', { productCode: this.cartItem.productCode })
      });
      this.$store.dispatch("customer/getCusPromotion");
    }
  }
}
</script>

<style lang="scss" scoped>
    .cart-item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #59C6BC;
        padding: 16px 0;
        &__left {
            padding: 0 16px;
            input[type=checkbox] {
                width: 30px;
                height: 30px;
            }
        }
        &__center {
            flex: 1;
            padding: 0 16px;
        }
        &__right {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__price {
            font-size: 20px;
            font-weight: 600;
            color: #B93D00;
            margin-top: 8px;
        }
    }
</style>
