<template>
    <div class="suggest-item" v-if="productItem" :class="[inCart(productItem) ? 'suggest-item--disable' : '']">
        <div class="suggest-item__content">
            <div class="suggest-item__name" v-if="productItem.productName">{{productItem.productName}}</div>
            <div class="suggest-item__price" v-if="productItem.price">{{productItem.price.toLocaleString('vi-VN', {style : 'currency', currency : 'VND'})}}</div>
        </div>
        <div class="suggest-item__action" v-on:click="addToCardHandle(productItem)">
            <CartSuggest />
        </div>
    </div>
</template>

<script>
import CartSuggest from '@/assets/cart-suggest.svg'
export default {
  components: {
    CartSuggest
  },
  props: {
    productItem: Object
  },
  methods: {
    inCart (product) {
      return !(this.$store.state.cart.cartItems.findIndex(item => item.productCode === product.productCode) === -1)
    },
    addToCardHandle (product) {
      if (this.$store.getters['auth/customerIsSelected']) {
        this.$store.dispatch('cart/addItem', { ...product, quantity: this.quantity })
      } else {
        this.$notify.error({
          duration: 1000,
          title: 'Thông báo!',
          message: 'Vui lòng chọn khách hàng'
        })
      }
    }
  }
}
</script>

<style lang="scss">
    .suggest-item {
        border: 1px solid #E7E3E4;
        display: flex;
        align-items: center;
        background-color: #EFF9F8;
        border-radius: 15px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        max-width: 445px;
        width: 100%;
        &--disable {
          opacity: 0.5;
          pointer-events: none;
        }
        &__price {
            font-weight: normal;
            color: #B93D00;
        }

        &__name {
            text-transform: uppercase;
        }
        &__content {
            flex: 1;
            border-right: solid 1px #E7E3E4;
            height: 100%;
            padding: 16px;
        }
        &__action {
            transition: all 0.3s;
            padding: 16px;
            cursor: pointer;
            &:hover {
                opacity: 0.75;
            }
        }
    }
</style>
