<template>
  <div class="page cart-page">
    <Header />
    <HotlineMobileCard />
    <div class="container">
      <div
        class="h5 font-weight--sm--500 text--sm--center mb--sm--3 mb--md--20 mt--sm--3 mt--md--15 forTabletUp"
      >
        Giỏ hàng của bạn
      </div>
      <div class="row mb--md--6">
        <div
          class="col col--sm--12 col--md--6"
          v-for="cartItem in cartItems"
          :key="cartItem.productCode"
        >
          <CartItem :cartItem="cartItem" v-model="cartSelectedItems" />
        </div>
        <div
          class="col col--sm--12 d--sm--flex justify-content--sm--center align-items--sm--center flex-direction--sm--column mb--sm--9 mb--md--20 mt--sm--9 mt--md--20"
          v-if="cartEmpty"
        >
          <div
            class="d--sm--flex justify-content--sm--center mb--sm--12 mb--md--20"
          >
            <NoMessageIcon />
          </div>
          <div class="text--sm--center mb--sm--12 mb--md--20">
            Giỏ hàng trống!
          </div>
          <div class="text--sm--center">
            <HdButton v-on:click="$router.push('/').catch(() => {})">
              Quay về trang chủ
              <template v-slot:icon><ArrowRightIcon /></template>
            </HdButton>
          </div>
        </div>
        <div class="col col--sm--12 text--sm--center mb--sm--3 mb--md-20"></div>
      </div>
      <HdButton
        class="mb--sm--3 mb--sm--20"
        v-if="!cartEmpty"
        v-on:click="checkAllClickHandle"
      >
        Chọn tất cả
        <template v-slot:icon><CheckIcon /></template>
      </HdButton>
      <SuggestList v-if="!cartEmpty" />
      <div class="row" v-if="!cartEmpty">
        <div class="col col--sm--12">
          <CartInfo />
        </div>
      </div>
      <CtkmCard v-if="!cartEmpty" />
      <BostonApp v-if="!cartEmpty" :active="$store.state.customer.promotionCode != ''"/>
      <CtkmX1Card v-if="!cartEmpty" :active="$store.state.customer.promotionX1Code != ''"/>
      <div class="text--sm--right mb--sm--3" v-if="!cartEmpty">
        <HdButton v-on:click="cartSubmitHandle">
          Mua hàng
          <template v-slot:icon><ArrowRightIcon /></template>
        </HdButton>
      </div>
    </div>
    <Footer />
    <FooterMobile />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import FooterMobile from "@/components/FooterMobile.vue";
import CartItem from "@/components/CartItem.vue";
import HotlineMobileCard from "@/components/HotlineMobileCard.vue";
import ArrowRightIcon from "@/assets/arrow-right.svg";
import CheckIcon from "@/assets/check-icon.svg";
import CartInfo from "@/components/CartInfo";
import HdButton from "@/components/HdButton";
import CtkmCard from "@/components/CtkmCard";
import CtkmX1Card from "@/components/CtkmX1Card";
import SuggestList from "@/components/SuggestList";
import NoMessageIcon from "@/assets/no-message.svg";
import BostonApp from "@/components/BostonApp.vue";

export default {
  components: {
    Header,
    Footer,
    CartItem,
    FooterMobile,
    HotlineMobileCard,
    ArrowRightIcon,
    CheckIcon,
    CartInfo,
    HdButton,
    CtkmCard,
    CtkmX1Card,
    SuggestList,
    NoMessageIcon,
    BostonApp
  },
  data() {
    return {};
  },
  methods: {
    checkAllClickHandle() {
      if (!this.isCheckAll) {
        this.cartSelectedItems = [];
        this.cartSelectedItems = this.cartItems.reduce(
          (cartSelectedItems, cartItem) => {
            cartSelectedItems.push(cartItem.productCode);
            return cartSelectedItems;
          },
          []
        );
      } else {
        this.cartSelectedItems = [];
      }
      this.$store.dispatch("customer/getCusPromotion");
    },
    cartSubmitHandle() {
      if (!this.$_.isEmpty(this.cartSelectedItems)) {
        if (!this.$_.isEmpty(this.saleDeal)) {
          if (this.cartSelectedItems.length > 0) {
            this.$router.push("checkout").catch(() => {});
          } else {
            this.$alert(
              "Xin vui lòng chọn ít nhất một sản phẩm để đặt hàng.",
              "Thông báo",
              {
                confirmButtonText: "OK",
                type: "warning"
              }
            );
          }
        } else {
          this.$alert("Bạn chưa chọn CTKM để đặt hàng!", "Thông báo", {
            confirmButtonText: "OK",
            type: "warning"
          });
        }
      } else {
        this.$alert("Bạn chưa chọn sản phẩm để đặt hàng!", "Thông báo", {
          confirmButtonText: "OK",
          type: "warning"
        });
      }
    }
  },
  computed: {
    saleDeal() {
      return this.$store.state.cart.saleDeal;
    },
    isCheckAll() {
      return this.cartSelectedItems.length === this.cartItems.length;
    },
    cartSelectedItems: {
      get() {
        return this.$store.state.cart.cartSelectedItems;
      },
      set(value) {
        this.$store.commit("cart/setCartSelectedItems", value);
      }
    },
    cartEmpty() {
      return this.$_.isEmpty(this.cartItems);
    },
    cartCount() {
      return this.$store.getters["cart/cartCount"];
    },
    cartItems: {
      get() {
        return this.$store.state.cart.cartItems;
      },
      set(cartItems) {
        this.$store.commit("cart/setCartItems", cartItems);
      }
    }
  },
  mounted() {
    this.$store.dispatch("setHeaderMobile", {
      heading: true,
      headingText: "Giỏ hàng của bạn",
      back: true,
      search: false,
      notify: true,
      cart: false
    });
    this.$store.dispatch("setFooterMobile", false);
    this.$store.dispatch("customer/getCusPromotion");
  }
};
</script>
