<template>
    <div class="ctkm">
      <div class="ctkm__choose">
        <div class="ctkm__choose__item">
          <div class="ctkm__label">CTKM HĐ</div>
          <div class="ctkm__icon" :class="[promotionType ==='HD' ? 'ctkm__icon--is-active' : '']" v-on:click="function(){
            setPromotionType('HD')
            setSaleDeal({})
          }"></div>
        </div>
        <div class="ctkm__choose__item">
          <div class="ctkm__label">CTKM LẺ</div>
          <div class="ctkm__icon" :class="[promotionType ==='LE' ? 'ctkm__icon--is-active' : '']" v-on:click="function(){
            setPromotionType('LE')
            setSaleDeal({})
          }"></div>
        </div>
      </div>
      <div class="ctkm__list">
       <div class="ctkm__item">
         <div class="ctkm__item__name">{{$_.isEmpty(saleDeal) ? 'CTKM' : saleDeal.name}}</div>
         <div class="ctkm__item__close" @click="function () {
           if ($_.isEmpty(saleDeal)) {
            $store.dispatch('cart/getPromotionCart')
            dialogVisible = true
           } else {
            setSaleDeal({})
           }
           }">
           <CtkmIcon :class="[!$_.isEmpty(saleDeal) ? 'pluss' : '']"/>
         </div>
       </div>
      </div>
      <el-dialog
        title="Chọn chương trình khuyến mãi"
        :visible.sync="dialogVisible">
        <div class="ctkm__dialog">
          <div class="ctkm__dialog__item"
            v-for="(ctkmItem, index) in ctkm"
            :key="index"
            :class="[saleDeal.value === ctkmItem.promotionCode? 'ctkm__dialog__item--is-selected' : '']"
            v-on:click="function(){
              setSaleDeal({
                value: ctkmItem.promotionCode,
                name: ctkmItem.promotionName
              })
              dialogVisible = false
            }">
            <div class="ctkm__dialog__item__code" v-if="ctkmItem.promotionCode">{{ctkmItem.promotionCode}}</div>
            <div class="ctkm__dialog__item__name" v-if="ctkmItem.promotionName">{{ctkmItem.promotionName}}</div>
            <CheckIcon class="ctkm__dialog__item__check-icon mr--sm--4" v-if="saleDeal.value === ctkmItem.promotionCode"/>
          </div>
          <div class="text--sm--center" v-if="$_.isEmpty(ctkm)">Không có chương trình khuyến mãi nào</div>
        </div>
      </el-dialog>
    </div>
</template>

<script>
import CtkmIcon from '@/assets/ctkm-close-icon.svg'

// Import Components
import CheckIcon from '@/assets/check-icon.svg'

export default {
  components: {
    CtkmIcon,
    CheckIcon
  },
  props: {},
  data () {
    return {
      dialogVisible: false
    }
  },
  methods: {
    setPromotionType: function (payload) {
      this.$store.dispatch('cart/setPromotionType', payload)
    },
    setSaleDeal: function (payload) {
      this.$store.commit('cart/setSaleDeal', payload)
    }
  },
  computed: {
    promotionType () {
      return this.$store.state.cart.promotionType
    },
    ctkm () {
      return this.$store.state.cart.ctkm
    },
    saleDeal () {
      return this.$store.state.cart.saleDeal
    }
  }
}
</script>

<style lang="scss" scoped>
.pluss {
  transform: rotate(45deg)
}
.ctkm__choose__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
    .ctkm {
        display: flex;
        align-items: center;
        &__label {
            font-size: 20px;
            font-weight: 500;
            margin-right: 15px;
            line-height: 40px;
            min-width: 160px;
        }
        &__icon{
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: solid 4px #5EB5AF;
            position: relative;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
                opacity: 0.75;
            }
            &--is-active {
&::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 8px;
                height: 8px;
                border-radius: 50%;
                border: solid 4px #19958C;
            }
            }

        }
        &__list {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            align-items: center;
        }
        &__item {
            height: 36px;
            line-height: 36px;
            background: rgba(243, 111, 32, 0.15);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            display: flex;
            align-items: center;
            margin-left: 20px;
            &__name {
                font-size: 20px;
                font-weight: 600;
                color: #B93D00;
                text-transform: uppercase;
                padding: 0 8px;
                flex: 1;
            }
            &__close {
                border-left: solid 1px #fff;
                height: 100%;
                display: flex;
                align-items: center;
                padding: 0 8px;
                cursor: pointer;
                transition: all 0.3s;
                &:hover {
                    opacity: 0.75;
                }
            }
        }
    }
    .ctkm__dialog {
    }
    .ctkm__dialog__item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #B93D00;
      padding: 16px 0;
      font-size: 16px;
      font-weight: 500;
      color: #B93D00;
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        background-color: #FAE6DA;
      }
      &--is-selected {
        background-color: #FAE6DA;
      }
    }
    .ctkm__dialog__item__code {
      font-weight: 600;
      width: 20%;
      max-width: 20%;
    }
    .ctkm__dialog__item__name {
      flex: 1;
    }
</style>
